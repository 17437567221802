// variables
$bg: #FFFFFF;
$content-max-width: 800px;
$footer-max-width: 1200px;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1024px,
        xl: 1200px
) !default;


$enable-shadows:          true;
$page-max-width:          1440px;
$oeamtc-yellow:           #ffe700;
$oeamtc-black:            #111111;
$oeamtc-grey:             #636363;
$oeamtc-grey-light:       #b4b4b4;
$oeamtc-grey-lighter:     #f4f4f4;
$oeamtc-icon-color:       #333333;
$oeamtc-green:            #45a617;
$primary:                 $oeamtc-yellow;
$footer:                  rgb(0, 109, 136);
$theme-colors: ();
$theme-colors: map-merge(
                (
                        "oeamtc-yellow": $oeamtc-yellow,
                        "oeamtc-black": $oeamtc-black,
                        "oeamtc-grey": $oeamtc-grey,
                        "oeamtc-grey-light": $oeamtc-grey-light,
                        "oeamtc-green": $oeamtc-green,
                        "footer": $footer
                ),
                $theme-colors
);

$body-bg:                   #FFFFFF;
$body-color:                $oeamtc-black;
$link-color:                $oeamtc-black; //$oeamtc-grey;
$link-hover-color:          darken($link-color, 5%);
$link-hover-decoration:     none;
$box-shadow:                0 .125rem 1.25rem rgba(black, .15); //0 2px 20px 0 rgba(0, 0, 0, 0.15);
$box-shadow-light:          0 2px 4px 0 rgba(0, 0, 0, 0.02);

$font-size-base:          1rem; // Assumes the browser default, typically `16px`
$font-family-sans-serif:  'Franklin Gothic Boo', Lato, Arial, sans-serif;
$headings-color:          $oeamtc-black;
$headings-font-family:    'Franklin Gothic Med', 'merriweather_sansbold', Arial, sans-serif;
$headings-font-weight:    700;

$font-family-bold:        $headings-font-family;

$font-family-nabar:  'Franklin Gothic Dem', Lato, Arial, sans-serif;


//$border-radius:           2px;
//$border-radius-lg:        2px;
//$border-radius-sm:        2px;

$popover-header-padding-x:1rem;
$popover-border-color:    transparent;
//$popover-border-radius:   4px;
$popover-box-shadow:      $box-shadow;

$nav-tabs-border-color:               transparent;
//$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            8px;
$nav-tabs-link-hover-border-color:    transparent;
$nav-tabs-link-active-color:          $oeamtc-black;
$nav-tabs-link-active-bg:             transparent;
$nav-tabs-link-active-border-color:   transparent;

$navbar-nav-link-padding-x:           1rem;

$dropdown-min-width: auto;

$carousel-control-opacity:           .9;
$carousel-control-hover-opacity:     1;

$card-cap-bg:                         #FFF;

$spacer:                  1rem;
$spacers: ();             // add more spacer classes
$spacers: map-merge(
                (
                        5: ($spacer * 2),
                        6: ($spacer * 3),
                        7: ($spacer * 4),
                        8: ($spacer * 5),
                        9: ($spacer * 6)
                ),
                $spacers
);

//$card-border-radius: 3px;
$card-border-color: transparent;
$card-border-color-header: #e8e8e8;
$card-spacer-x: 2.5rem;

//$modal-content-border-radius:       $border-radius;
$modal-header-border-color:         #FFF;
$modal-footer-border-color:         #FFF;
$modal-content-border-color:        #FFF;
$modal-content-box-shadow-xs:       $box-shadow;
$modal-content-box-shadow-sm-up:    $box-shadow;

$popover-max-width:                 320px;
$zindex-popover:                    999; // underneath oeamtc menu and header

$modal-sm:                          350px;

// imports
@import "bootstrap.scss";
@import "font-awesome.scss";
@import "loader.scss";
@import "select2.scss";
@import "widget.scss";
@import "fonts";
//@import '~bootstrap/scss/bootstrap';

// Generate font-size utility classes.
// Must be defined after bootstrap imports.
// .font-size-{value} for xs
// .font-size-{breakpoint}-{value} for sm, md, lg, and xl.
// eg .font-size-sm-14
$font-sizes: (
        10: 0.625rem,
        12: 0.75rem,
        14: 0.875rem,
        15: 0.9375rem,
        16: 1rem,
        18: 1.125rem,
        20: 1.25rem,
        22: 1.375rem,
        24: 1.5rem,
        28: 1.75rem,
        40: 2.5rem
);
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $key, $value in $font-sizes {
      .font-size#{$infix}-#{$key} { font-size: $value; }
    }
  }
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  min-height: 100vh;
}

.clickable {
  cursor: pointer;
}

.container-fluid {
  max-width: $content-max-width;
}

.container-fluid--footer {
  max-width: $content-max-width;
}
@include media-breakpoint-up('lg') {
  .container-fluid--footer {
    max-width: $footer-max-width;
  }
}

// rows within a container expanding to 100% oft the viewport
// see https://css-tricks.com/full-width-containers-limited-width-parents/
.full-width {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

.full-width--header-img {
  background-size: cover;
  background-position: center;
  height: 348px;
  margin-bottom: 3rem;
}

.full-width--booking {
  background-color: rgba(0, 109, 136, 1);
  @extend .py-3;
  margin-bottom: 3rem;
  position: sticky;
  top: 0px;
  z-index: 100;
}

.full-width--booking-alpha {
  background-color: rgba(0, 109, 136, .8);
}

.carousel-container {
  margin-bottom: 3rem;
}

.carousel-item__bg-image {
  background-size: cover;
  background-position: center;
  height: 348px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 60px;
  height: 60px;
  background-size: 35% 35%;
  background-color: rgba(255, 255, 255, .5);
  border-radius: 100%;
}

.header {
  background-color: $primary;
  display: flex;
  padding: 1.5rem 1.5rem;
  justify-content:space-between;
  align-items: center;
}

.header__logo {
  flex-grow: 1;
  max-width: 210px;
}

.header__nav {
  flex-grow: 5;
  font-size: 1.25rem;
  font-family: $font-family-nabar;
}

.header__language {
  flex-grow: 1;
  max-width: 210px;
}

@include media-breakpoint-up('lg') {
  .navbar .nav-item {
    margin-left: 2px;
    margin-right: 2px;
  }

  .header__nav {
    margin-right: 210px;
  }
}

.navbar .nav-link {
  border-radius: $nav-tabs-border-radius;
  white-space: nowrap;
}

.navbar .nav-link:hover {
  background-color: #dabe0b;
}

.navbar .nav-link.active-path {
  background-color: #FFFFFF;
}

.nav-pills--language {
  justify-content: flex-end;
}

.nav-item--language {
  @extend .bg-white;
  border-radius: 6px;
}

.nav-pills .show.nav-item--language {
  @extend .bg-white;
}
.nav-pills.nav-pills--language .show > .nav-link {
  @extend .bg-white;
  color: #000;
}

.nav-pills--language .dropdown-menu.show {
  width: 100%;
  border:1px solid #FFF;
  top: -4px !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills--language .dropdown-item {
  padding-left: 1rem;
}

.language-txt {
  @extend .font-weight-bold;
  display: inline-block;
  padding-left: .25rem;
  text-transform: uppercase;
}

.navbar-toggler {
  align-self: flex-end;
  justify-self: flex-end;
  margin-left: auto;
  padding-right:0;
  @extend .font-size-24;
}

@include media-breakpoint-down('md') {

  .navbar-toggler-icon {
    height: auto;
  }

  #header-navbar {
    position: initial;
  }

  #header-navbar-collapse {
    position: absolute;
    top: 158px;
    padding: .75rem 1rem;
    right: 0;
    background-color: $primary;
    min-width: 400px;
    max-width: 100vw !important;
    z-index: 1000;
  }

  .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@include media-breakpoint-down('sm') {
  #header-navbar-collapse {
    width: 100%;
    min-width: auto;
  }
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  user-select: none;
}

.footer {
  position: relative;
  color: #FFFFFF;
  background-color: $footer;
  min-height: 100px;
  padding-top: 176px;
  @extend .pb-5;
}

.footer a {
  color: #FFFFFF;
}

.footer:before {
  position: absolute;
  top: 0px;
  height: 176px;
  width: 100%;
  content: '';
  background-image: url("../images/bg-footer.png");
  background-repeat: repeat-x;
  background-position: bottom center;
  background-color: $body-bg;
}

.hr--footer {
  border-top: 1px solid theme-color-level('footer', -2);
  margin: 2rem 3rem;
}

.footer__social-media {
  text-align: center;
}

.footer-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@include media-breakpoint-up('xl') {
  .footer-content-wrapper {
    flex-direction: row-reverse;
  }
}

.footer-camping-info {
  display: inline-block;
  align-self: center;
}

@include media-breakpoint-up('xl') {
  .footer-camping-info {
    align-self: flex-start;
  }
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: flex-start;
}

@include media-breakpoint-up('md') {
  .footer-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
}

.footer-content__contact {
  display: inline-block;
  align-self: center;
  min-width: 340px;
}

@include media-breakpoint-up('md') {
  .footer-content__contact {
    align-self: flex-start;
    min-width: auto;
  }
}

.footer-content__opening-hours {
  display: inline-block;
  align-self: center;
  min-width: 340px;
}

@include media-breakpoint-up('md') {
  .footer-content__opening-hours {
    align-self: flex-start;
    min-width: 430px;
  }
}

.footer-logo {
  display: none;
}

@include media-breakpoint-up('xl') {
  .footer-logo {
    display: inline-block;
  }
}


.icon-text {
  position: relative;
  padding-left:1.8rem;
  background-repeat: no-repeat;
  background-position: top 4px left 0px;
  margin-bottom: .5rem;
}

.icon-text--address {
  background-image: url("../images/icons/campsite.svg");
}

.icon-text--phone {
  background-image: url("../images/icons/phone.svg");
}

.icon-text--print {
  background-image: url("../images/icons/print.svg");
}

.icon-text--mail {
  background-image: url("../images/icons/email.svg");
  background-position: top 5px left 0px;
}

.icon-text--pdf {
  background-image: url("../images/icons/pdf.svg");
  background-position: top 6px left 0px;
}

.icon-text--pdf-pl {
  padding-left: 2rem;
}

.row--opening-hours {
  margin-bottom: .5rem;
}

.table .td--price-headline {
  padding-top: 1.5rem;
  border-top: 1px solid transparent;
}

.table .td--price-section {
  @extend .font-weight-bold;
  padding-top: 2rem;
}

.table .tr--price-spacer td {
  padding-top: 1.5rem;
  border-top: 1px solid transparent;
}

.font-weight-bold, b {
  font-family: $font-family-bold;
}

.icon-list {
  min-height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 1.5rem;
  margin-bottom: 1rem;
}

.icon-list__icon_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height:60px;
  min-width: 60px;
  min-height:60px;
  border-radius: 100%;
  background-color: #0071bb;
}

.icon-list__icon {
 @extend .icon-filter--white;
}

// see https://stackoverflow.com/questions/22252472/how-can-i-change-the-color-of-an-svg-element
.icon-filter--white {
  filter: invert(86%) sepia(73%) saturate(2%) hue-rotate(23deg) brightness(105%) contrast(101%);
}

.oeamtc-icon {
  max-height: 1.5rem;
}

.col--gallery-image {
  padding: .7rem .7rem .3rem .7rem;
  @extend .col-6;
}

@include media-breakpoint-up('sm') {
  .col--gallery-image {
    @extend .col-sm-4;
  }
}

.gallery-image {
  display: inline-block;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
  border:1px solid rgba(0,0,0, 0.075);
  border-radius: 6px;
  overflow:hidden;
}

.gallery-thumbnail {
  position:absolute;
  top: 8px;
  left: 8px;
  bottom: 8px;
  right: 8px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  transition: all .5s;
}

.gallery-image:hover .gallery-thumbnail,
.gallery-image:focus .gallery-thumbnail {
  transform: scale(1.075);
}

.col--leisure-activity {
  padding: .4rem .4rem .1rem .4rem;
  @extend .col-6;
}

@include media-breakpoint-up('sm') {
  .col--leisure-activity {
    @extend .col-sm-4;
  }
}

.leisure-activity {
  display: inline-block;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative;
  border:1px solid rgba(0,0,0, 0.075);
  border-radius: 6px;
  overflow:hidden;
}

.leisure-activity__thumbnail {
  position:absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .5s;
}

.leisure-activity__thumbnail:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0 ,0, 0, .25);
  z-index: 1;
  transition: all .5s;
}

.leisure-activity:hover .leisure-activity__thumbnail:before {
  background-color: rgba(0 ,0, 0, .6);
}

.leisure-activity:hover .leisure-activity__thumbnail {
  transform: scale(1.075);
}

.leisure-activity__title {
  @extend .font-weight-bold;
  color: #FFFFFF;
  @extend .font-size-24;
  z-index: 2;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.page-headline {
  @extend .mb-3;
}

.section-headline {
  @extend .mb-3;
  @extend .mt-5;
  scroll-margin-top: 30px;
}

#notifications-container {
  position: fixed;
  z-index: 1;
  width: 100%;
  max-width: 800px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

#notifications {
  width: 100%;
}



