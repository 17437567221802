/*
  see https://google-webfonts-helper.herokuapp.com/fonts/open-sans?subsets=latin
*/

@font-face {
  font-family: 'Franklin Gothic Dem';
  src: url('../fonts/FranklinGothicURW-Dem.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Franklin Gothic Med';
  src: url('../fonts/FranklinGothicURW-Med.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Franklin Gothic Boo';
  src: url('../fonts/FranklinGothicURW-Boo.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

